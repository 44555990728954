import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useAuth } from "react-oidc-context";
import { createBrowserRouter, Navigate, Outlet, useLocation } from "react-router-dom";

import App from "../App";
import { LoadingSpinner } from "../assets/svg";
import AuthenticationStore from "../auth";
import ErrorBoundaryDisplay from "../components/ErrorBoundaryDisplay";
import { FullPageWidthLayout, LeftSidebarLayout } from "../components/Layout";
import { RoutePathType } from "../enums";
import UserMembershipProvider from "../contexts/UserMembershipContext";
import RedirectPage from "../pages/Main/RedirectPage";
import { getEnv } from "../utils/get_env";

const SignIn = React.lazy(() => import("../pages/Authentication/SignIn"));

const Upload = React.lazy(() => import("../pages/Main/Upload"));
const AllModels = React.lazy(() => import("../pages/Main/AllModels"));
const Settings = React.lazy(() => import("../pages/Main/Settings"));

const File = React.lazy(() => import("../pages/Main/File"));
const ModelArtifact = React.lazy(() => import("../pages/Main/File/ModelArtifact"));

const Artifact = React.lazy(() => import("../pages/Main/Artifact"));

const UsersPage = React.lazy(() => import("../pages/Main/Admin/UsersPage"));
const AdminPanel = React.lazy(() => import("../pages/Main/Admin/AdminPanel"));
const PageNotFound = React.lazy(() => import("../components/PageNotFound"));

function SuspenseFallback() {
  return (
    <div
      data-testid="SuspenseFallback-loading-spinner"
      className="flex items-center justify-center w-full h-screen color-primary-400"
    >
      <LoadingSpinner width="50px" height="50px" />
    </div>
  );
}

function UserMemPagesLayout() {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <UserMembershipProvider>
        <Outlet />
      </UserMembershipProvider>
    </Suspense>
  );
}

function AppWithErrorBoundary() {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryDisplay}>
      <App />
    </ErrorBoundary>
  );
}

function AuthManager() {
  const location = useLocation();
  const auth = useAuth();
  const AS = AuthenticationStore();
  const redirectPath = AS.getRedirectPath();

  return auth.isAuthenticated ? (
    <Suspense fallback={<SuspenseFallback />}>
      {redirectPath ? <RedirectPage redirectUrl={redirectPath} /> : <Outlet />}
    </Suspense>
  ) : (
    <Navigate to={`${RoutePathType.Root}${RoutePathType.SignIn}`} state={{ from: location }} replace />
  );
}

export const router = createBrowserRouter(
  [
    {
      path: RoutePathType.Root,
      element: <AppWithErrorBoundary />,

      children: [
        {
          path: RoutePathType.SignIn,
          element: <SignIn />,
        },

        {
          element: <AuthManager />,
          children: [
            {
              element: <UserMemPagesLayout />,
              children: [
                {
                  path: "/",
                  element: <Navigate to={`./${RoutePathType.Upload}`} />,
                },
                {
                  element: <LeftSidebarLayout />,
                  children: [
                    {
                      path: RoutePathType.Upload,
                      element: <Upload />,
                    },
                    {
                      path: RoutePathType.AllFiles,
                      element: <AllModels />,
                    },
                    {
                      path: RoutePathType.AdminPanel,
                      element: <AdminPanel />,
                    },
                    {
                      path: `${RoutePathType.AdminPanel}/users`,
                      element: <UsersPage />,
                    },
                    {
                      path: RoutePathType.Settings,
                      element: <Settings />,
                    },
                  ],
                },

                {
                  element: <FullPageWidthLayout />,
                  children: [
                    {
                      path: `${RoutePathType.File}/:fileId/:versionId?`,
                      element: <File />,
                    },
                    {
                      path: `${RoutePathType.File}/:fileId/:versionId/artifact/:artifactId/:artifactVersionId?`,
                      element: <ModelArtifact />,
                    },
                    {
                      path: `/artifact/:artifactId/:artifactVersionId?`,
                      element: <Artifact />,
                    },
                    {
                      path: RoutePathType.NotFound,
                      element: <PageNotFound />,
                    },
                    {
                      path: "*",
                      element: <PageNotFound />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  {
    basename: getEnv("BASE_URL"),
  },
);
