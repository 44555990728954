import React, { useMemo } from "react";

import { CheckCircle, ErrorOutline, FileError, GenericFile, LoadingSpinner } from "../../assets/svg";
import { FileUploadStatusType } from "../../enums";
import { ThemeColors } from "../../themes";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip";

interface FileStatusRowProps {
  fileName: string;
  errorMsg?: string;
  status?: string;
}

interface MessageWithIconProps {
  icon: React.ReactNode;
  message: string;
  type: string;
}

const MessageWithIcon = ({ icon, message, type }: MessageWithIconProps) => {
  let color;
  switch (type) {
    case "info":
      color = "color-blue-400";
      break;
    case "success":
      color = "color-secondary-600";
      break;
    default:
      color = "color-red-300";
  }

  return (
    <div className="flex gap-x-8px items-center">
      <span className={`capitalize subtitle3b whitespace-nowrap ${color}`}>{message}</span>
      {icon}
    </div>
  );
};

const FileStatusRow = ({ fileName, errorMsg, status }: FileStatusRowProps) => {
  const renderUploadStatus = useMemo(() => {
    if (status === FileUploadStatusType.Uploading) {
      return (
        <MessageWithIcon
          type="info"
          message={status}
          icon={<LoadingSpinner width="18px" height="18px" color={ThemeColors.blue[400]} />}
        />
      );
    }
    if (status === FileUploadStatusType.Uploaded)
      return (
        <MessageWithIcon type="success" message={status} icon={<CheckCircle color={ThemeColors.secondary[600]} />} />
      );

    return (
      <MessageWithIcon
        type="error"
        message="File was not uploaded"
        icon={<ErrorOutline color={ThemeColors.red[400]} />}
      />
    );
  }, [status]);

  const renderFileIcon = useMemo(() => {
    if (errorMsg) return <FileError color={ThemeColors.red[300]} width="28px" height="28px" />;

    return <GenericFile width="24px" height="24px" />;
  }, [errorMsg]);

  return (
    <div className="px-16px py-10px bg-white-0 grow gap-4px">
      <div className="flex row items-center justify-between gap-8px w-full max-w-full">
        <div className="flex gap-x-8px items-center">
          {renderFileIcon}

          <div className="flex gap-4px">
            <Tooltip>
              <TooltipTrigger aria-label={fileName}>
                <div className="subtitle3b color-gray-800 max-w-250px truncate">{fileName}</div>
              </TooltipTrigger>
              <TooltipContent className="tooltip text-sm">{fileName}</TooltipContent>
            </Tooltip>
          </div>
        </div>

        <div className="flex basis-1/4 gap-x-8px items-center justify-end" data-testid="fileStatusRow-item-status">
          {renderUploadStatus}
        </div>
      </div>
      {errorMsg && <div className="text-sm color-red-300 break-words">{errorMsg}</div>}
    </div>
  );
};

export default FileStatusRow;
