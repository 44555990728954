import { LazyArtifact, LazyArtifactPage, LazyFile, LazyModel, LazyRevision } from "@istari/istari-client";
import { AccessRelationship, PatchOp } from "@istari/istari-client/dist/src/openapi";
import { WasmProperties } from "@istari/istari-wasm";
import { useMutation, useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import { showToast } from "../components";
import { HttpStatusCodeType } from "../enums";
import { sortArrayByKeyAndOrder } from "../utils/array";
import { getFileNameAndExtension } from "../utils/file";
import clientSDK from "./clientSDK";

export interface Model {
  artifacts: [];
  file: {
    revisions: LazyRevision[];
    id: string;
    version?: string | undefined;
  };
  id: string;
}

export type ModelProperties = {
  displayFileName?: string | undefined;
  extension: string | undefined;
};

export interface ModelData {
  model: {
    artifacts: LazyArtifact[];
    file: {
      revisions: LazyRevision[];
      id: string;
      version?: string | undefined;
    };
    id: string;
  };
  modelContent?:
    | {
        bytes: number[] | undefined; // ArrayBuffer;
      }
    | undefined;
  modelProperties: {
    description: string | undefined;
    extension: string | undefined;
    mime: string | undefined;
    fileName: string;
    size: number;
    versionName: string | undefined;
  };
}

export type ModelArtifact = LazyArtifact & {
  extension: string;
  id: string | undefined;
  fileName: string;
  artifactId: string;
  displayFileName?: string;
};

export interface ListOptions {
  size: number;
  page: number;
  sort: string | undefined;
  createdBy?: string;
}

export const modelQueryKeys = {
  all: [],
  item: (id: string) => [...modelQueryKeys.all, id],
  list: (filter: ListOptions) => [...modelQueryKeys.all, "list", filter],
  tasks: (modelId: string, filter: ListOptions) => [...modelQueryKeys.item(modelId), "tasks", filter],
  artifacts: (modelId: string, filter: ListOptions) => [...modelQueryKeys.item(modelId), "artifacts", filter],
  currentContent: (modelId: string) => [...modelQueryKeys.item(modelId), "currentContent"],
  allArtifacts: (modelId: string) => [...modelQueryKeys.item(modelId), "allArtifacts"],
  accessList: (modelId: string) => [...modelQueryKeys.item(modelId), "accessList"],
  artifactsProperties: (artifacts: LazyRevision[]) => [...modelQueryKeys.all, "artifactsProperties", artifacts],
};

export const artifactQueryKeys = {
  all: ["artifacts"],
  item: (id: string) => [...artifactQueryKeys.all, id],
  list: (filter: ListOptions) => [...artifactQueryKeys.all, "list", filter],
};

export interface ModelProps {
  id: string;
  created: string;
  description: string;
  extension: string;
  external_identifier: string | undefined;
  mime: string | undefined;
  name: string;
  size: number;
  version_name: string | undefined;
}

export interface ModelType extends LazyModel, WasmProperties {
  created: Date;
  displayFileName?: string;
  modelAccessList: AccessRelationship[];
}

export const useUpload = () => useMutation({ mutationFn: (file: File) => clientSDK.addModelLazy(file) });

export const useGetModels = ({ filter }: { filter: ListOptions }) =>
  useQuery({
    queryKey: modelQueryKeys.list(filter),
    queryFn: () =>
      clientSDK
        .listModelsLazy(filter.page, filter.size, filter.createdBy, filter.sort)
        .then(async (data) => {
          const list = data.items.map(async (model) => {
            const modelPropsData = [];
            // const modelAccessList = await clientSDK.access.listModelAccess({ modelId: model.id });

            const properties = await model.readCurrentProperties();

            modelPropsData.push({
              ...model,
              ...properties,
              id: model.id,
              created: model.file.revisions[0].created,
              displayFileName: properties.displayName || getFileNameAndExtension(properties.fileName)?.name,
              // modelAccessList,
            });

            return modelPropsData;
          });

          const result = await Promise.all(list).then((response) => response.flat());

          return {
            items: result,
            total: data.total,
          };
        })
        .catch((error: Error) => {
          console.error("Error loading models", error);
          if (error.message.includes(String(HttpStatusCodeType.Forbidden))) {
            showToast("Your session may have expired. Try refresh the page.", "error");
          } else {
            showToast("An error occurred while loading the models. Try refresh the page.", "error");
          }
          return {
            items: [],
            total: 0,
          };
        }),
  });

export const useGetArtifacts = ({
  filter,
  listArtifactsOnlyState,
}: {
  filter: ListOptions;
  listArtifactsOnlyState: boolean;
}) =>
  useQuery({
    queryKey: artifactQueryKeys.list(filter),
    queryFn: () =>
      clientSDK
        .listArtifactsLazy(filter.page, filter.size, filter.createdBy, filter.sort)
        .then(async (data: LazyArtifactPage) => {
          const list = data.items.map(async (artifact: LazyArtifact) => {
            const properties = await artifact.readCurrentProperties();

            return {
              ...artifact,
              ...properties,
              id: artifact.id,
              created: artifact.file.revisions[0].created,
              displayFileName: properties.displayName || getFileNameAndExtension(properties.fileName)?.name,
            };
          });

          const result = await Promise.all(list).then((response) => response.flat());

          return {
            items: result,
            total: data.total,
          };
        })
        .catch((error: Error) => {
          console.error("Error loading artifacts", error);
          showToast("An error occurred while loading the artifacts", "error");
          return {
            items: [],
            total: 0,
          };
        }),
    ...{ enabled: listArtifactsOnlyState },
  });

export const useGetModelAccessById = (fileId: string | undefined, enabled: boolean) =>
  useQuery({
    queryKey: modelQueryKeys.accessList(fileId!),
    queryFn: () => clientSDK.listModelAccess(fileId!),
    ...{ enabled },
  });

export const useGetArtifactAccessById = (artifactId: string | undefined, enabled: boolean) =>
  useQuery({
    queryKey: modelQueryKeys.accessList(artifactId!),
    queryFn: () => clientSDK.listArtifactAccess(artifactId!),
    ...{ enabled },
  });

export const useGetModel = (modelId: string) =>
  useQuery({
    queryKey: modelQueryKeys.item(modelId),
    queryFn: () =>
      clientSDK
        .getModelLazy(modelId)
        .then(async (model: LazyModel) => {
          const modelProperties = await model.readCurrentProperties();
          const modelAccessList = await clientSDK.listModelAccess(modelId);

          const revs = model.file.revisions.map(async (rev: LazyRevision) => {
            const revProp = await rev.readProperties();
            return { ...rev, ...revProp };
          });

          const modelPropertiesWithDisplayName = {
            ...modelProperties,
            displayFileName: modelProperties.displayName || getFileNameAndExtension(modelProperties.fileName)?.name,
          };

          const modelFullRevisions = await Promise.all(revs);

          return {
            model,
            modelAccessList,
            modelFullRevisions: sortArrayByKeyAndOrder(modelFullRevisions, "created", "desc"),
            modelProperties: modelPropertiesWithDisplayName,
          };
        })
        .catch((error: Error) => {
          console.error("Error loading model", error);
          if (
            error.message.includes(String(HttpStatusCodeType.Forbidden)) ||
            error.message.includes(String(HttpStatusCodeType.NotFound))
          ) {
            throw new Error("This model is not found or you do not have permission to view this model.");
          } else {
            throw new Error("An error occurred while loading the model", error);
          }
        }),
  });

export const useGetModelCurrentContent = (modelId: string, enabled: boolean) =>
  useQuery({
    queryKey: modelQueryKeys.currentContent(modelId),
    queryFn: () =>
      clientSDK
        .getModelLazy(modelId)
        .then(async (model: LazyModel) => {
          const modelContent = await model.readCurrentContent();
          return modelContent.bytes;
        })
        .catch((error: Error) => {
          console.error("Error loading model content", error);
        }),
    ...{ enabled },
  });

interface UploadModelVersionPayloadType {
  modelId: string;
  file: File;
  description?: string;
  externalIdentifier?: string;
  versionName?: string;
}

export const useUploadModelVersion = () =>
  useMutation({
    mutationFn: (data: UploadModelVersionPayloadType) =>
      clientSDK.updateModelLazy(data.modelId, data.file, [], data.description, undefined, data.externalIdentifier),
  });

export const downloadFile = (modelContentBytes: number[], name: string | undefined, extension: string | undefined) => {
  if (modelContentBytes && name && extension) {
    const blob = new Blob([new Uint8Array(modelContentBytes)]);
    saveAs(new Blob([blob], { type: extension }), `${name}.${extension}`);
    showToast(`${name}.${extension} has been downloaded successfully.`, "success");
  } else {
    showToast("An error occurred while downloading the file.", "error");
  }
};

type ShareModelAccessRelationship = {
  accessRelationship: AccessRelationship;
  patchOpType: PatchOp;
};

export interface ShareFilePayload {
  modelId: string;
  accessGrants: ShareModelAccessRelationship[];
  resourceType: "model" | "artifact";
}

export const useShareFile = () =>
  useMutation({
    mutationFn: async (data: ShareFilePayload) => {
      let promises;
      if (data.resourceType === "model") {
        promises = data.accessGrants.map((item) =>
          clientSDK.patchModelAccess(data.modelId, item.accessRelationship, item.patchOpType),
        );
      } else {
        promises = data.accessGrants.map((item) =>
          clientSDK.patchArtifactAccess(data.modelId, item.accessRelationship, item.patchOpType),
        );
      }
      await Promise.all(promises);
    },
  });

type ExtractModelPayload = {
  functionName: string;
  modelId: string;
  operatingSystem: string;
  parametersFile: File;
  toolKey: string;
  toolVersion: string;
  revisionId: string[] | undefined;
};

export const useExtractModel = () =>
  useMutation({
    mutationFn: (data: ExtractModelPayload) =>
      clientSDK.addJobLazy(
        data.modelId,
        data.parametersFile,
        data.functionName,
        data.toolKey,
        data.toolVersion,
        data.operatingSystem,
        undefined,
        data.revisionId,
      ),
  });

export interface ArtifactType extends LazyArtifact, WasmProperties {
  created: Date;
  artifactAccessList: AccessRelationship[];
  id: string;
  model_id: string;
  displayFileName?: string;
}

export type LazyRevisionWithArtifactId = LazyRevision & { artifactId: string; displayFileName?: string | undefined };

export const useGetAllArtifactsProperties = (artifacts: LazyRevisionWithArtifactId[] | undefined) =>
  useQuery({
    queryKey: modelQueryKeys.artifactsProperties(artifacts!),
    queryFn: async () => {
      if (artifacts) {
        const results = await Promise.all(
          artifacts.map(async (artifact) => {
            const properties = await artifact.readProperties();

            return {
              ...artifact,
              ...properties,
              displayFileName: properties.displayName || getFileNameAndExtension(properties.fileName)?.name,
              id: artifact.id,
            };
          }),
        );

        return {
          items: results,
          total: artifacts?.length,
        };
      }
      return {
        items: [],
        total: 0,
      };
    },
  });

export const useGetArtifact = (artifactId: string) =>
  useQuery({
    queryKey: artifactQueryKeys.item(artifactId),
    queryFn: () =>
      clientSDK
        .getArtifactLazy(artifactId)
        .then(async (artifact: LazyArtifact) => {
          const artifactProperties = await artifact.readCurrentProperties();
          const artifactAccessList = await clientSDK.listArtifactAccess(artifactId);
          const artifactContent = await artifact.readCurrentContent();

          return {
            artifact,
            artifactAccessList,
            artifactContent: artifactContent.bytes,
            displayFileName:
              artifactProperties.displayName || getFileNameAndExtension(artifactProperties.fileName)?.name,
            artifactProperties,
          };
        })
        .catch((error: Error) => {
          console.error("Error loading artifact", error);
          if (
            error.message.includes(String(HttpStatusCodeType.Forbidden)) ||
            error.message.includes(String(HttpStatusCodeType.NotFound))
          ) {
            throw new Error("This artifact is not found or you do not have permission to view this artifact.");
          } else {
            throw new Error("An error occurred while loading the artifact", error);
          }
        }),
  });

export const useRemoveModel = () =>
  useMutation({
    mutationFn: (data: { modelId: string }) => clientSDK.deleteModelLazy(data.modelId),
  });

export const useRenameModel = () =>
  useMutation({
    mutationFn: (data: { file: LazyFile; name: string }) => clientSDK.updateFilePropertiesLazy(data.file, data.name),
  });

export const useListModelJobs = ({ modelId, filter }: { modelId: string; filter: ListOptions }) =>
  useQuery({
    queryKey: modelQueryKeys.tasks(modelId, filter),
    queryFn: () =>
      clientSDK
        .listJobsLazy(modelId, undefined, filter.page, filter.size, filter.sort)
        .then((data) => data)
        .catch((error: Error) => {
          console.error("Error loading model jobs", error);
          showToast("An error occurred while loading the model jobs", "error");
          return [];
        }),
  });
