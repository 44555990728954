import { useMutation, useQuery } from "@tanstack/react-query";

import { profileService } from "../services";

interface UserFormData {
  firstName: string;
  lastName: string;
  displayName: string;
}

interface UpdatePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export const userProfileKeys = {
  all: ["selfprofile"],
  item: (id: number | string | null) => [...userProfileKeys.all, id],
};

export const useGetUserProfile = () =>
  useQuery({
    queryKey: userProfileKeys.all,
    queryFn: () => profileService.get("/users/me/profile"),
    ...{ refetchOnWindowFocus: false },
  });

export const useUpdateUserNames = () =>
  useMutation({
    mutationFn: (data: UserFormData) => profileService.put("/users/me/profile", data),
  });

export const useUpdatePassword = () =>
  useMutation({
    mutationFn: (data: UpdatePasswordPayload) => profileService.put("/users/me/password", data),
  });

export const useMyMemberships = () =>
  useQuery({
    queryKey: userProfileKeys.item(null),
    queryFn: () => profileService.post("/memberships/me/_search"),
    ...{ refetchOnWindowFocus: false },
  });
