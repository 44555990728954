import { WebStorageStateStore } from "oidc-client-ts";

import { getEnv } from "../utils/get_env";

const redirectUri = {
  post_logout_redirect_uri: getEnv("VITE_LOGOUT_REDIRECT_URI") || "http://localhost:3000",
  redirect_uri: getEnv("VITE_REDIRECT_URI") || "http://localhost:3000",
};

export const authConfig = {
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  authority: getEnv("VITE_ZITADEL_AUTHORITY"),
  client_id: getEnv("VITE_ZITADEL_CLIENT_ID"),
  response_type: "code",
  scope:
    "openid profile email offline_access urn:zitadel:iam:org:project:id:zitadel:aud urn:zitadel:iam:user:resourceowner",
  ...redirectUri,
};
