import { useQuery } from "@tanstack/react-query";

import clientSDK from "./clientSDK";

export const useListOrgUsers = () =>
  useQuery({
    queryKey: ["allUsers"],
    queryFn: () =>
      clientSDK
        .listUsersLazy("active")
        .then((res) => res)
        .catch((err: Error) => {
          throw new Error("An error occurred while loading user list ", err);
        }),
  });
